export const ModelState ={
    NONE: 'none',
    SQUIRTLE: 'squirtle',
    CHARMANDER: 'charmander'
}

export const charmanderStrings = [
    "The Goose is Roost",
    "Mr. President",
    "She's all I Swant",
    "But daddy I love her",
    "Silly Goose"

]
export const squirtleStrings = [
    "King of the castle",
    "The King of Queens",
    "My Squishy",
    "The Squince \nwho was squomised",
    "Lord of cinders",
    "King Squilliam Squisherton\nfirst of his name, pooper of pants"
]
